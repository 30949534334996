// Copyright Google Inc. All Rights Reserved.
(function() { /*

 Copyright The Closure Library Authors.
 SPDX-License-Identifier: Apache-2.0
*/
'use strict';var g;function h(a){var b=0;return function(){return b<a.length?{done:!1,value:a[b++]}:{done:!0}}}function k(a){var b="undefined"!=typeof Symbol&&Symbol.iterator&&a[Symbol.iterator];return b?b.call(a):{next:h(a)}}function l(a){if(!(a instanceof Array)){a=k(a);for(var b,c=[];!(b=a.next()).done;)c.push(b.value);a=c}return a}var n="function"==typeof Object.defineProperties?Object.defineProperty:function(a,b,c){if(a==Array.prototype||a==Object.prototype)return a;a[b]=c.value;return a};
function aa(a){a=["object"==typeof globalThis&&globalThis,a,"object"==typeof window&&window,"object"==typeof self&&self,"object"==typeof global&&global];for(var b=0;b<a.length;++b){var c=a[b];if(c&&c.Math==Math)return c}throw Error("Cannot find global object");}var p=aa(this);function q(a,b){if(b)a:{var c=p;a=a.split(".");for(var d=0;d<a.length-1;d++){var e=a[d];if(!(e in c))break a;c=c[e]}a=a[a.length-1];d=c[a];b=b(d);b!=d&&null!=b&&n(c,a,{configurable:!0,writable:!0,value:b})}}
function r(){for(var a=Number(this),b=[],c=a;c<arguments.length;c++)b[c-a]=arguments[c];return b}q("Symbol",function(a){function b(f){if(this instanceof b)throw new TypeError("Symbol is not a constructor");return new c(d+(f||"")+"_"+e++,f)}function c(f,m){this.g=f;n(this,"description",{configurable:!0,writable:!0,value:m})}if(a)return a;c.prototype.toString=function(){return this.g};var d="jscomp_symbol_"+(1E9*Math.random()>>>0)+"_",e=0;return b});
q("Symbol.iterator",function(a){if(a)return a;a=Symbol("Symbol.iterator");for(var b="Array Int8Array Uint8Array Uint8ClampedArray Int16Array Uint16Array Int32Array Uint32Array Float32Array Float64Array".split(" "),c=0;c<b.length;c++){var d=p[b[c]];"function"===typeof d&&"function"!=typeof d.prototype[a]&&n(d.prototype,a,{configurable:!0,writable:!0,value:function(){return ba(h(this))}})}return a});function ba(a){a={next:a};a[Symbol.iterator]=function(){return this};return a}
function ca(a,b){a instanceof String&&(a+="");var c=0,d=!1,e={next:function(){if(!d&&c<a.length){var f=c++;return{value:b(f,a[f]),done:!1}}d=!0;return{done:!0,value:void 0}}};e[Symbol.iterator]=function(){return e};return e}q("Array.prototype.entries",function(a){return a?a:function(){return ca(this,function(b,c){return[b,c]})}});q("Object.entries",function(a){return a?a:function(b){var c=[],d;for(d in b)Object.prototype.hasOwnProperty.call(b,d)&&c.push([d,b[d]]);return c}});
q("Object.is",function(a){return a?a:function(b,c){return b===c?0!==b||1/b===1/c:b!==b&&c!==c}});q("Array.prototype.includes",function(a){return a?a:function(b,c){var d=this;d instanceof String&&(d=String(d));var e=d.length;c=c||0;for(0>c&&(c=Math.max(c+e,0));c<e;c++){var f=d[c];if(f===b||Object.is(f,b))return!0}return!1}});
q("String.prototype.includes",function(a){return a?a:function(b,c){if(null==this)throw new TypeError("The 'this' value for String.prototype.includes must not be null or undefined");if(b instanceof RegExp)throw new TypeError("First argument to String.prototype.includes must not be a regular expression");return-1!==(this+"").indexOf(b,c||0)}});var t=this||self;function da(a,b,c){return a.call.apply(a.bind,arguments)}
function ea(a,b,c){if(!a)throw Error();if(2<arguments.length){var d=Array.prototype.slice.call(arguments,2);return function(){var e=Array.prototype.slice.call(arguments);Array.prototype.unshift.apply(e,d);return a.apply(b,e)}}return function(){return a.apply(b,arguments)}}function u(a,b,c){Function.prototype.bind&&-1!=Function.prototype.bind.toString().indexOf("native code")?u=da:u=ea;return u.apply(null,arguments)}
function v(a,b){function c(){}c.prototype=b.prototype;a.M=b.prototype;a.prototype=new c;a.prototype.constructor=a;a.L=function(d,e,f){for(var m=Array(arguments.length-2),C=2;C<arguments.length;C++)m[C-2]=arguments[C];return b.prototype[e].apply(d,m)}};t.cast=t.cast||{};var w=t.cast,cast=null;function x(a){if(Error.captureStackTrace)Error.captureStackTrace(this,x);else{var b=Error().stack;b&&(this.stack=b)}a&&(this.message=String(a))}v(x,Error);x.prototype.name="CustomError";function y(a,b){a=a.split("%s");for(var c="",d=a.length-1,e=0;e<d;e++)c+=a[e]+(e<b.length?b[e]:"%s");x.call(this,c+a[d])}v(y,x);y.prototype.name="AssertionError";function fa(a,b){throw new y("Failure"+(a?": "+a:""),Array.prototype.slice.call(arguments,1));};function z(){this.g=Date.now()}var A=null;z.prototype.set=function(a){this.g=a};z.prototype.get=function(){return this.g};function B(a,b){this.name=a;this.value=b}B.prototype.toString=function(){return this.name};var D=new B("OFF",Infinity),E=new B("SEVERE",1E3),F=new B("WARNING",900),ha=new B("INFO",800),G=new B("CONFIG",700),ia=new B("FINE",500);function ja(){}var H;function I(a,b,c){this.g=void 0;a=a||D;this.j=Date.now();this.h=a;this.l=b;this.i=c;this.g=void 0}function J(a,b){this.g=null;this.i=[];this.h=(void 0===b?null:b)||null;this.l=[];this.j={g:function(){return a}}}
function K(a){if(a.g)return a.g;if(a.h)return K(a.h);fa("Root logger has no level set.");return D}function ka(a,b){for(;a;)a.i.forEach(function(c){c(b)}),a=a.h}function la(){this.entries={};var a=new J("");a.g=G;this.entries[""]=a}var L;function M(a,b,c){var d=a.entries[b];if(d)return void 0!==c&&(d.g=c),d;d=M(a,b.substr(0,b.lastIndexOf(".")));var e=new J(b,d);a.entries[b]=e;d.l.push(e);void 0!==c&&(e.g=c);return e}function N(){L||(L=new la);return L}
function O(a,b,c){var d;if(d=a)if(d=a&&b){d=b.value;var e=a?K(M(N(),a.g())):D;d=d>=e.value}d&&(b=b||D,d=M(N(),a.g()),"function"===typeof c&&(c=c()),H||(H=new ja),a=new I(b,c,a.g()),a.g=void 0,ka(d,a))}function ma(a){var b=P;b&&O(b,E,a)}function Q(a){var b=P;b&&O(b,F,a)}function na(a){var b=P;b&&O(b,ha,a)}function oa(a){var b=P;b&&O(b,ia,a)};function R(a){this.g=a||"";A||(A=new z);this.h=A}g=R.prototype;g.o=!0;g.u=!0;g.D=!0;g.C=!0;g.B=!1;g.F=!1;function S(a){return 10>a?"0"+a:String(a)}function T(a){R.call(this,a)}v(T,R);
function pa(a,b){var c=[];c.push(a.g," ");if(a.u){var d=new Date(b.j);c.push("[",S(d.getFullYear()-2E3)+S(d.getMonth()+1)+S(d.getDate())+" "+S(d.getHours())+":"+S(d.getMinutes())+":"+S(d.getSeconds())+"."+S(Math.floor(d.getMilliseconds()/10)),"] ")}if(a.D){d=c.push;var e=a.h.get();e=(b.j-e)/1E3;var f=e.toFixed(3),m=0;if(1>e)m=2;else for(;100>e;)m++,e*=10;for(;0<m--;)f=" "+f;d.call(c,"[",f,"s] ")}a.C&&c.push("[",b.i,"] ");a.F&&c.push("[",b.h.name,"] ");c.push(b.l);a.B&&(b=b.g,void 0!==b&&c.push("\n",
b instanceof Error?b.message:String(b)));a.o&&c.push("\n");return c.join("")};function qa(){this.l=u(this.i,this);this.g=new T;this.g.u=!1;this.g.B=!1;this.h=this.g.o=!1;this.j={}}function ra(){var a=U;if(1!=a.h){var b=M(N(),"").j,c=a.l;b&&M(N(),b.g()).i.push(c);a.h=!0}}qa.prototype.i=function(a){function b(f){if(f){if(f.value>=E.value)return"error";if(f.value>=F.value)return"warn";if(f.value>=G.value)return"log"}return"debug"}if(!this.j[a.i]){var c=pa(this.g,a),d=sa;if(d){var e=b(a.h);ta(d,e,c,a.g)}}};var U=null,sa=t.console;
function ta(a,b,c,d){if(a[b])a[b](c,void 0===d?"":d);else a.log(c,void 0===d?"":d)};w.debug={};w.debug.G=function(a){a.innerHTML="<style>.cast-debug-label{position:fixed;top:3vh;left:3vw;color:#fff;background-color:#f00;font-size:30pt;display:block;z-index:999999}.cast-debug-logs{position:fixed;top:3vh;right:3vw;left:3vw;bottom:3vh;background-color:#fff;opacity:.8;display:none;z-index:999999}.cast-debug-logs .logger-block{padding-left:.5vw;padding-top:.5vh;padding-bottom:.5vh;color:#000;background-color:#fff}.cast-debug-logs .logger-warning{color:#630;background-color:#ffffb3}.cast-debug-logs .logger-error{color:#f00;background-color:#fcc}</style><div class=cast-debug-label>DEBUG MODE</div><div class=cast-debug-logs></div>"};function ua(){this.g=w.framework.CastReceiverContext.getInstance()}
function V(a){var b=this;U||(U=new qa);U&&ra();if(!(a instanceof ua))throw ma("CastDebugLogger object is a singleton. Please call CastDebugLogger.getInstance() instead."),Error("CastDebugLogger is not created properly.");this.l=a.g;this.l.addCustomMessageListener("urn:x-cast:com.google.cast.debuglogger",function(c){na("Message received: "+JSON.stringify(c));switch(c.data.command){case "SHOW":b.v(!0);break;case "HIDE":b.v(!1);break;case "CLEAR":b.A();break;case "CLEARCACHE":b.h?(window.location.reload(!0),
window.close()):Q("CastDebugLogger is disabled.");break;default:Q("Unsupported command")}});this.h=!1;this.g=this.s=this.i=null;this.j=new T;this.j.u=!1;this.j.D=!0;this.j.C=!0;this.j.F=!1;this.j.o=!1;this.loggerLevelByEvents={};this.l.getPlayerManager().addEventListener(w.framework.events.EventType.ALL,this.H.bind(this))}g=V.prototype;
g.H=function(a){if(this.loggerLevelByEvents){var b="cast.framework.events.EventType."+a.type;b in this.loggerLevelByEvents&&this.m("Event_"+a.type,this.loggerLevelByEvents[b],a);"cast.framework.events.EventType.ALL"in this.loggerLevelByEvents&&this.m("Events_ALL",this.loggerLevelByEvents["cast.framework.events.EventType.ALL"],a);b=k(Object.entries(w.framework.events.category));for(var c=b.next();!c.done;c=b.next()){var d=k(c.value);c=d.next().value;d=d.next().value;var e="cast.framework.events.category."+
c;e in this.loggerLevelByEvents&&d.includes(a.type)&&this.m("Events_"+c,this.loggerLevelByEvents[e],a)}}};
g.I=function(a){this.l.getDeviceCapabilities().is_device_registered&&a?this.h=!0:this.h=!1;this.i||(!this.i&&document.querySelector("cast-debug-overlay")?this.i=document.querySelector("cast-debug-overlay"):document.querySelector("cast-debug-overlay")||(this.i=document.createElement("cast-debug-overlay"),w.debug.G(this.i),document.body.appendChild(this.i)),this.s=document.querySelector(".cast-debug-label"),this.g=document.querySelector(".cast-debug-logs"));this.i.style.display=this.h?"block":"none";
Q("CastDebugLogger enabled = "+this.h)};g.v=function(a){this.h?a?(this.g.style.display="block",this.s.style.display="none"):(this.g.style.display="none",this.s.style.display="block"):Q("CastDebugLogger is disabled.")};g.A=function(){this.h?this.g.textContent="":Q("CastDebugLogger is disabled.")};g.debug=function(a){this.m.apply(this,[a,w.framework.LoggerLevel.DEBUG].concat(l(r.apply(1,arguments))))};g.info=function(a){this.m.apply(this,[a,w.framework.LoggerLevel.INFO].concat(l(r.apply(1,arguments))))};
g.warn=function(a){this.m.apply(this,[a,w.framework.LoggerLevel.WARNING].concat(l(r.apply(1,arguments))))};g.error=function(a){this.m.apply(this,[a,w.framework.LoggerLevel.ERROR].concat(l(r.apply(1,arguments))))};
g.m=function(a,b){var c=r.apply(2,arguments);if(va(this,a,b)){c=c.map(function(f){return"string"!==typeof f?JSON.stringify(f):f}).join(" ");var d="logger-info",e="";switch(b){case w.framework.LoggerLevel.INFO:na("["+a+"] "+c);e="[INFO] "+c;break;case w.framework.LoggerLevel.DEBUG:d="logger-debug";oa("["+a+"] "+c);e="[DEBUG] "+c;break;case w.framework.LoggerLevel.WARNING:d="logger-warning";Q("["+a+"] "+c);e="[WARN] "+c;break;case w.framework.LoggerLevel.ERROR:d="logger-error";ma("["+a+"] "+c);e="[ERROR] "+
c;break;case w.framework.LoggerLevel.NONE:return;default:Q("Unsupported logger level.");return}e=pa(this.j,new I(null,e,a));this.l.sendCustomMessage("urn:x-cast:com.google.cast.debuglogger","*:*",{customTag:a,logLevel:b,logMessage:c,logString:e});wa(this,e,d)}};
function wa(a,b,c){if(null!=a.g&&"block"==a.g.style.display){var d=document.createElement("div");d.classList.add("logger-block",c);d.textContent=b;25<=a.g.childElementCount&&a.g.removeChild(a.g.lastChild);a.g.insertBefore(d,a.g.firstChild)}}
function va(a,b,c){if(!a.h)return!1;var d=w.framework.LoggerLevel.DEBUG;a.loggerLevelByTags&&a.loggerLevelByTags.hasOwnProperty(b)&&(d=a.loggerLevelByTags[b]);switch(d){case w.framework.LoggerLevel.NONE:case w.framework.LoggerLevel.ERROR:case w.framework.LoggerLevel.WARNING:case w.framework.LoggerLevel.INFO:case w.framework.LoggerLevel.VERBOSE:case w.framework.LoggerLevel.DEBUG:return c>=d?!0:!1;default:return Q("Unsupported logger level."),!1}}var W=["cast","debug","CastDebugLogger"],X=t;
W[0]in X||"undefined"==typeof X.execScript||X.execScript("var "+W[0]);for(var Y;W.length&&(Y=W.shift());)W.length||void 0===V?X[Y]&&X[Y]!==Object.prototype[Y]?X=X[Y]:X=X[Y]={}:X[Y]=V;V.getInstance=function(){Z||(Z=new V(new ua));return Z};V.prototype.error=V.prototype.error;V.prototype.warn=V.prototype.warn;V.prototype.info=V.prototype.info;V.prototype.debug=V.prototype.debug;V.prototype.clearDebugLogs=V.prototype.A;V.prototype.showDebugLogs=V.prototype.v;V.prototype.setEnabled=V.prototype.I;
var Z=null,P=M(N(),"cast.debug.CastDebugLogger",void 0).j;V.Commands={SHOW:"SHOW",HIDE:"HIDE",J:"CLEAR",K:"CLEARCACHE"}; }).call(window);
